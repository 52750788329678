<template>
	<div class="container">
		<div class="top">
			<el-input v-model="input" placeholder="请输入设备序列号进行搜索"></el-input>
			<el-button type="primary" icon="el-icon-search">搜索</el-button>
			<el-select v-model="user_type" placeholder="请选择设备类型">
				<el-option label="管理员" value="1"></el-option>
				<el-option label="客户" value="2"></el-option>
				<el-option label="终端用户" value="3"></el-option>
			</el-select>
			<el-select v-model="user_type" placeholder="请选择设备名称">
				<el-option label="管理员" value="1"></el-option>
				<el-option label="客户" value="2"></el-option>
				<el-option label="终端用户" value="3"></el-option>
			</el-select>
			<el-select v-model="user_type" placeholder="请选择设备型号">
				<el-option label="管理员" value="1"></el-option>
				<el-option label="客户" value="2"></el-option>
				<el-option label="终端用户" value="3"></el-option>
			</el-select>
			<el-date-picker class="time" v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
			 start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="change">
			</el-date-picker>
			<el-button type="primary" icon="el-icon-plus" class="add-username" @click="add">导出报表</el-button>
		</div>
		<el-table :data="tableData" stripe style="width: 100%" :max-height="$store.state.clientHeight">
			<el-table-column prop="id" label="序号" width="120">
			</el-table-column>
			<el-table-column prop="username" label="时间日期" width="250">
			</el-table-column>
			<el-table-column prop="Email" label="终端用户名称">
			</el-table-column>
			<el-table-column prop="usernametype" label="设备名称">
			</el-table-column>
			<el-table-column prop="corporateName" label="设备序列号">
			</el-table-column>
			<el-table-column prop="creatTime" label="洗衣龙编号">
			</el-table-column>
			<el-table-column prop="details" label="信号详情" min-width="260">
			</el-table-column>
		</el-table>
		<el-pagination class="footer" background layout="prev, pager, next, jumper" :total="1000">
		</el-pagination>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				pickerOptions: { // 限制收货时间不让选择今天以前的
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
					}
				},
				chart: '',
				time: '',
				activeName: "first",
				router: false,
				input: '',
				user_type: '',
				tableData: [{
						id: '1',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '2',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '3',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '4',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '5',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '6',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '7',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '8',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '9',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '10',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '11',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '12',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '13',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '14',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					},
					{
						id: '15',
						username: 'q981115394',
						Email: '981115394@qq.com',
						usernametype: '管理员',
						corporateName: '公司名称',
						creatTime: '2020/12/29',
						details: '洗衣机信号状态:开始  程序选择:程序1  程序状态:已结束  电磁阀信号状态:开启'
					}
				]
			}
		},
		created() {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
			this.time = [start, end]
		},
		methods: {
			// 添加账号
			add() {
				this.$router.push('/Home/EndUserList/AddEndUserList')
			},
			// 删除指定账号
			del(e) {
				this.$confirm(this.fun.getLanguageText(this.$store.state.language, '此操作将永久删除该文件, 是否继续?'),
						this.fun.getLanguageText(this.$store.state.language, '提示'), {
						confirmButtonText: this.fun.getLanguageText(this.$store.state.language, '确定'),
						cancelButtonText: this.fun.getLanguageText(this.$store.state.language, '取消'),
					type: 'warning'
				}).then(() => {
					// 执行删除操作
					this.tableData.splice(e.$index, 1);
					this.$message({
						type: 'success',
						message: this.fun.getLanguageText(this.$store.state.language, '删除成功!')
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 计算高度
			getListHeight() {
				let Html = document.getElementsByClassName('box-card')
				if (Html.length > 0) {
					this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60

				}
			}
		},
		mounted() {
			this.getListHeight()
			window.onresize = () => {
				return (() => {
					this.getListHeight()
				})();
			}
		},
		destroyed() {
			window.onresize = null;
		}
	};
</script>

<style lang="less" scoped>
	.el-input {
		width: 240px;
		margin-right: 10px;
	}

	.el-select {
		width: 180px;
		margin-left: 20px;
	}

	.time {
		margin-left: 20px;
		width: 260px;
	}

	.container {
		display: flex;
		flex-direction: column;
	}

	.top {
		// height: 32px;
		margin-bottom: 20px;
		position: relative;
	}

	.add-username {
		position: absolute;
		right: 0;
	}

	.footer {
		display: flex;
		padding: 0;
		margin-top: 14px;
		justify-content: flex-end;
	}

	.state-item {
		width: 400px;
		height: 350px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		font-size: 14px;
		border: 1px solid #BBBBBB;
		// justify-content: flex-start;
		// align-items: flex-start;

		.item-title {

			width: 100%;
			height: 54px;
			background-color: #E1F0F9;
			color: #000000;
			font-size: 16px;
			font-family: SourceHanSansSC-bold;
			text-align: center;
			line-height: 54px;

		}

		.item-1 {
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;
			text-align: center;
		}

		.item-3 {
			width: 33.333%;
			height: 40px;
			text-align: center;
			word-wrap: break-word;
			// line-height: 40px;
		}

		.item-2 {
			box-sizing: border-box;
			width: 50%;
			height: 34px;
			text-align: center;
			line-height: 34px;
			border: 1px solid rgba(240, 240, 240, 100);
		}
	}
</style>
